import React, { useState, useEffect ,useRef}  from 'react'; 
import { Routes,Route, NavLink, useNavigate, Link,useParams } from 'react-router-dom';
import { apiUrl } from '../Api';
import './detail.css';  
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Loader from '../loader/Loader';

const ImgDetails = () => {     
    
   const username    = localStorage.getItem('name');
   let base64        = require('base-64');
   const navigate    = useNavigate(); 
   const  leadId     = base64.decode(useParams().id) ; 
   const  leadtype   = useParams().type; 
 
  useEffect(() =>{
    getSolutions();
  },[leadId]);
  
  const [loading, setLoading] = useState(false);
  const usr_token = localStorage.getItem('authToken'); 
  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('name');
    localStorage.removeItem('isAuthenticatedUser');
    localStorage.removeItem('usrId');
    localStorage.removeItem('usrRole');
    navigate('/'); 
  };

  const [imageSrc, setImageSrc]     = useState("/img/holisollogo.jpeg"); 
  const [imageExt, setImageExt]     = useState("pptx");  
  const [imageName, setImageName]   = useState();    
  const [imageList, setSliderImage] = useState([]); 
  
  const changeImage = async(data) => {

    var clickInfo =    data.split('~~');
      setImageSrc(clickInfo[1]);
      let ext = getExtension(clickInfo[0]);
      setImageExt(ext);
      setImageName(clickInfo[0]);

      console.log("selected image "+clickInfo[0]);
  };

  const onValueChange = async (e) => {  
    try {
        navigate('/search');
    } catch (error) {
        console.log(error);
    }
  };

  
  const getSolutions = async (e) => {
    setLoading(true)
     
    try {
     fetch((apiUrl.concat('category/leadsolution')), {
     // fetch(('http://localhost:8580/gallery/category/leadsolution'), { 
      method: "POST", 
      body:JSON.stringify({leadId, leadtype}),
      headers: {
          "Content-Type": "application/json",
          'Authorization' : 'Bearer '+ usr_token,
      },
      })
      .then((response) => response.json())
      .then((response) => {

          setLoading(false)

          if(response.status == false){
          navigate('/');
          localStorage.removeItem('isAuthenticatedUser'); 
              
          }else{ 

          let sliding = response.solution;
 
          if(response.solution != ''){
            let ext = getExtension(response.solution[0].name);
            setImageExt(ext);
            setImageName(response.solution[0].name)
              
            setImageSrc(response.solution[0].ImgUrl);
          }
          setSliderImage(sliding); 
          }
      });
    } catch (error) {
        console.log(error);
    }

  };

  
  function getExtension(filename) {
    return filename.split('.').pop()
  }
  //const [SolutionData, setSolutionData] = React.useState([""]); 
   
    const [errorMessage, setErrorMessage] = useState(''); 
    const [SolutionData, setSolutionData] = useState([]);   
    const [page, setPage] = useState(0);
    const [countData, setCountData] = useState(0);

    console.log('show data '+SolutionData.length);
    console.log('all Data '+countData);
     

  useEffect(() => {
    // Fetch initial data when the component mounts
    getSearchSolutions();
  }, []);



  const getSearchSolutions = async (e) => {
    setLoading(true)
    
    var lob      = ''; 
    let project  = ''; 
    let service  = '';  
    let supplier = "";
    let oem      = "";
    let cfamily  = "";
    let cname    = "";
    let proposed = "";
    let type     = "";

   

    let lobname         = sessionStorage.getItem("lobnamemydash"); 
    let productname     = sessionStorage.getItem("productnamemydash");
    let servicename     = sessionStorage.getItem("servicenamemydash");
    let suppliername    = sessionStorage.getItem("suppliernamemydash");
    let oemname         = sessionStorage.getItem("oemnamemydash");
    let componentfamily = sessionStorage.getItem("componentfamilydash");
    let componentname   = sessionStorage.getItem("componentnamedash");
    let proposedname    = sessionStorage.getItem("proposednamedash");
    let typename        = sessionStorage.getItem("typenamedash");

    if(lobname != '' && lobname != null){
        let lobName = JSON.parse(lobname); 
        var lob = lobName.value ; 
    } 
    
    if(productname != '' && productname != null){
        let proName = JSON.parse(productname); 
        project= proName.value ;
    }  
    if(servicename != '' && servicename != null){
      let serName = JSON.parse(servicename); 
      service= serName.value ;    //open for searching service dropdown
    }  

    if(suppliername != '' && suppliername != null){
      let supVar = JSON.parse(suppliername); 
      supplier= supVar.value ;     
    }  
    if(oemname != '' && oemname != null){
      let oemVar = JSON.parse(oemname); 
      oem = oemVar.value ;    
    }  
    if(componentfamily != '' && componentfamily != null){
      let CFamilyVar = JSON.parse(componentfamily); 
      cfamily= CFamilyVar.value ;    
    }  
    if(componentname != '' && componentname != null){
      let CNameVar = JSON.parse(componentname); 
      cname = CNameVar.value ;    
    }  
    if(proposedname != '' && proposedname != null){
      let propVar = JSON.parse(proposedname); 
      proposed= propVar.value ;    
    }  
    if(typename != '' && typename != null){
      let typeVar = JSON.parse(typename); 
      type= typeVar.value ;    
    } 

    console.log(JSON.stringify({leadId,leadtype,lob,project,service,supplier,oem,cfamily,cname,proposed,type,page}));    

    try { 
      //fetch(('http://localhost:8580/gallery/category/search'), {  
        fetch((apiUrl.concat('category/search')), {     //category/lob_list for old data
        method: "POST", 
        body:JSON.stringify({leadId,leadtype,lob,project,service,supplier,oem,cfamily,cname,proposed,type,page}),
        headers: {
          "Content-Type": "application/json",
          'Authorization' : 'Bearer '+ usr_token,
        },
      })
      .then((response) => response.json())
      .then((response) => {

          setLoading(false)
         
          if(response.status == false){ 
            setErrorMessage("No Results Found !");
            setSolutionData([])
             
          }else{ 

            setErrorMessage("View Solution !");
            let newData = response.solution;  
            setSolutionData(prevData => [...prevData, ...newData]); 
            setPage(prevPage => prevPage + 9); 
            const dataCount = response.solutionCount;
            setCountData(dataCount);
            
          }
      });
    } catch (error) {
        console.log(error);
    }
    
  };

  
  useEffect(() => {
      
      
  },[countData]);

  const handleLoadMore = () => {
    getSearchSolutions();
  }; 

  const handleLinkClick = () => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };  


   
 
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
    //   slidesToSlide: 8 // optional, default to 1.
    //   partialVisibilityGutter: 40 
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
    //   slidesToSlide: 5 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4,
    //   slidesToSlide: 4 // optional, default to 1.
    }
  };
  

  return (
    <>
    {loading && (
      <>
        <Loader/>
      </>
    )}   
    <div className="container-fluid nav-bar bg-transparent">
      <nav className="navbar navbar-expand-lg bg-white navbar-light py-0 px-4">
            <NavLink to={'/'} className="navbar-brand d-flex align-items-center text-center">
                <div className="icon p-2 me-2">
                    <img className="img-fluid" src="/img/holisollogo.jpeg" alt="Icon" style={{ width: '120px' }} />
                </div>
                <h1 className="m-0 text-primary">Gallery</h1>
            </NavLink>
            <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto">
                    <div className="wrap">
                        <div className="search">
                            <input type="text" className="searchTerm" onClick={onValueChange} placeholder="What are you looking for?" />
                            <button type="submit" className="searchButton">
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="nav-item dropdown">
                        <div className="icon3" style={{marginRight:'56px'}}>

                            <img className="img-fluid" src="../../img/user.png" alt="Icon" /><br></br>

                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown"><strong>{username}</strong></a>
                            <div className="dropdown-menu ">
                                <Link onClickCapture={logout} className="dropdown-item">Logout</Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
      </div>

      <div className= "card-wrapper">
        <div className= "card" >
          <div className= "product-imgs">
            <div className= "img-display">
                <div className= "img-showcase" style={{ position: 'relative', marginLeft:'170px'}}>
                   
                    {(imageExt == 'jpg' ||  imageExt == 'png' ||  imageExt == 'PNG' ) &&
                        <img src = {imageSrc}  alt = "Solution"  title=' ' style={{width:'700px',height:'500px'}}/>  }

                    {(imageExt == 'ppt' || imageExt == 'xlsx' || imageExt == 'pptx' ) &&
                        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(imageSrc)}`}  style={{width:'700px',height:'500px'}} >   </iframe> 
                                            
                    }
                    { (imageExt == 'docx') &&
                        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(imageSrc)}#wdSlideId=3`} style={{height:'300px' ,width:'405px'  }} position="absolute" > </iframe>
                    }  

                    {(imageExt == 'PDF' || imageExt == 'pdf') &&

                        <iframe   src= {`${imageSrc}#toolbar=0&navpanes=0&#toolbar=0&scrollbar=0`}    type="application/pdf"  style={{width:'700px',height:'500px'}} />
                    
                        // <iframe src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(imageSrc)}`}    style={{width:'700px',height:'500px'}} />  
                        // <iframe   src={imageSrc}   type="application/pdf"  style={{width:'700px',height:'500px'}} />
                        //  <iframe   src="http://infolab.stanford.edu/pub/papers/google.pdf#toolbar=0&navpanes=0&#toolbar=0&scrollbar=0"
                        //   style={{width:'700px', height:'500px'}}  ></iframe>
                            // <iframe  src="https://drive.google.com/viewerng/viewer?embedded=true&url=http://infolab.stanford.edu/pub/papers/google.pdf#toolbar=0&scrollbar=0"  style={{width:'700px',height:'500px'}} /> 
                        

                    } 
                                            
                    <div className="overlay"  >
                        <marquee behavior="scroll" direction="left">
                            {imageName} 
                        </marquee>
                    </div>  

                    {(imageExt == 'PDF' || imageExt == 'pdf') && 
                    <div className="pdfzoomoverlay"  >  <a href={imageSrc} style={{textAlign:'center'}} target='blank'><i class="fa fa-search"></i></a></div> } 

                     {(imageExt == 'jpg' ||  imageExt == 'png' ||  imageExt == 'PNG' ) &&
                        <div className="pdfzoomoverlay"  >  <a href={imageSrc} style={{textAlign:'center', marginRight:'300px'}} target='blank'><i class="fa fa-search"></i></a></div> }     
                </div> 
            </div>
            {/* <div className= "img-select">
            {imageList != '' && imageList.map((info,index)=> {
                 let ext = getExtension(info.name);
                        return(
                <div className= "img-item">
                  <Link onClick={()=>changeImage(info.name+'~~'+info.ImgUrl)} data-id = {index}>

                  {(ext == 'jpg' ||  ext == 'png' ||  ext == 'PNG' ) &&
                                        <img src = {info.ImgUrl} alt = {info.name}  style={{width:'90px',height:'80px'}}/> }

                  {  (ext == 'ppt' || ext == 'pptx' ) &&
                           
                       <img src = "/img/ppt.png" alt = {info.name}  style={{width:'90px',height:'80px'}}/> }   

                  {  (ext == 'pdf' || ext == 'PDF' ) &&
                           
                       <img src = "/img/pdf.png" alt = {info.name}  style={{width:'90px',height:'80px'}}/> } 

                  {  (ext == 'xlsx' || ext == 'XLSX' ) &&
                           
                      <img src = "/img/xlsx.png" alt = {info.name}  style={{width:'90px',height:'80px'}}/> }     
                   
                  </Link>
                </div>

            ) })}
            </div> */}
          </div>
          
          {/* <div className= "product-content">
            <h2 className= "product-title">npm solution  </h2>
            <a href = "#" className= "product-link">( Lead Id:  {leadId} )</a>

            <div className= "product-detail">
              <h2>About this solution: </h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo eveniet veniam tempora fuga tenetur placeat sapiente architecto illum soluta consequuntur, aspernatur quidem at sequi ipsa!</p>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, perferendis eius. Dignissimos, labore suscipit. Unde.</p>
               
                <li>Ext: <span>{imageExt}</span></li>
                <li><b>LOB</b>: <span>{imageName}</span></li>
                <li><b>PROJECT </b> : <span>All over the world</span></li>
                <li><b>SERVICES </b>: <span>Free</span></li>
              
            </div>

           
          </div> */}
        </div>
      </div>
        <div className='row' >
            <div className='col-md-3' style={{marginLeft:'15px'}}>
            </div>
            <div className= "col-md-5" style={{width:'733px', marginLeft:'55px'}}>
                <Carousel
                 autoPlay={true}
                 autoPlaySpeed={1000}
                 swipeable={true}
                 draggable={true}
                //showDots={true}
                 infinite={true}
                 partialVisible={false}
                 dotListClass="custom-dot-list-style"
                 responsive={responsive}>
                    {imageList != '' && imageList.map((info,index)=> {
                        let ext = getExtension(info.name);
                        return(
                        <div className= "img-item">
                            <Link onClick={()=>changeImage(info.name+'~~'+info.ImgUrl)} data-id = {index}>

                            {(ext == 'jpg' ||  ext == 'png' ||  ext == 'PNG' ) &&
                                                    <img src = {info.ImgUrl} alt = {info.name}  style={{width:'90px',height:'80px'}}/> }

                            {  (ext == 'ppt' || ext == 'pptx' ) &&
                                    
                                <img src = "/img/ppt.png" alt = {info.name}  style={{width:'90px',height:'80px'}}/> }   

                            {  (ext == 'pdf' || ext == 'PDF' ) &&
                                    
                                <img src = "/img/pdf.png" alt = {info.name}  style={{width:'90px',height:'80px'}}/> } 

                            {  (ext == 'xlsx' || ext == 'XLSX' ) &&
                                    
                                <img src = "/img/xlsx.png" alt = {info.name}  style={{width:'90px',height:'80px'}}/> } 

                            {  (ext == 'docx' ) &&
                                    
                                <img src = "/img/docx.jpeg" alt = {info.name}  style={{width:'90px',height:'80px'}}/> }     
                            
                            </Link>
                        </div>  
                    ) })}
                </Carousel>
            </div>
            <div className='col-md-5'>
            </div>
        </div>
      <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-0 gx-5 align-items-end">
                    <div className="col-lg-6">
                        <div className="text-start mx-auto mb-5 wow slideInLeft" data-wow-delay="0.1s">
                            <h1 className="mb-3">Other Solution  </h1>
                           {/*} <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit eirmod sit diam justo sed rebum.</p> {*/}
                        </div>
                    </div>
                   
                </div>
                <div className="tab-content">
                    <div id="tab-1" className="tab-pane fade show p-0 active">

                                    
                        <div className="row g-4"> 
                            {SolutionData != '' && SolutionData.map((SolutionData)=> { 
                            let ext = getExtension(SolutionData.name); 
                            return(
                                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                        <div className="property-item rounded overflow-hidden">
                                            <div className="position-relative overflow-hidden">
                                               
                                               <Link to={'/details/'+base64.encode(SolutionData.Id)+'/'+SolutionData.Type}  > 
                                             {/*}   <Link to={'/details/2'}>    {*/}
                                                    {(ext == 'jpg' ||  ext == 'png' ||  ext == 'PNG' ) &&
                                                        <img className="img-fluid" src={SolutionData.ImgUrl}  style={{height:'300px' ,width:'405px'  }}/> 
                                                    } 
                                                    { (SolutionData.ImgUrl != '') && (ext == 'ppt' || ext == 'xlsx' || ext == 'pptx' ) &&
                                                        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(SolutionData.ImgUrl)}`} style={{height:'300px' ,width:'405px'  }} position="absolute"  > </iframe>
                                                    }

                                                    { (SolutionData.ImgUrl != '') && (ext == 'pdf' || ext == 'PDF') &&
                                                    
                                                    // <iframe src={`https://docs.google.com/gview?url=${encodeURIComponent(SolutionData.ImgUrl)}&embedded=true`} style={{height:'300px' ,width:'405px'  }}>
                                                    // </iframe> 
                                                    <embed   src={SolutionData.ImgUrl}   type="application/pdf"  style={{height:'300px' ,width:'405px'  }}  />
                                                    }
                                                    { (SolutionData.ImgUrl != '') && (ext == 'docx') &&
                                                    
                                                     <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(SolutionData.ImgUrl)}`} style={{height:'300px' ,width:'405px'  }} position="absolute"  > </iframe>
                                                    }
                                                    
                                                </Link>
                                                { (SolutionData.ImgUrl != '') && (ext == 'pdf' || ext == 'PDF') &&
                                      
                                                <div className="pdfzoomoverlayList"  >  <a href={SolutionData.ImgUrl} style={{textAlign:'center'}} target='blank' title='View Pdf'><i class="fa fa-search"></i></a></div>
                                                 }
                                                  {(ext == 'jpg' ||  ext == 'png' ||  ext == 'PNG' ) &&
                                      
                                                    <div className="pdfzoomoverlayList"  >  <a href={SolutionData.ImgUrl} style={{textAlign:'center'}} target='blank' title='View '><i className="fa fa-search"></i></a></div>
                                                  }
                                            
                                            </div>
                                            <div className="p-4 pb-0">
                                            
                                            <Link to={'/details/'+base64.encode(SolutionData.Id)+'/'+SolutionData.Type} onClick={handleLinkClick}   className="d-block h5 mb-2" href=""><marquee behavior="scroll" direction="left"> {SolutionData.name} </marquee></Link>
                                               {/*} <p><i className="fa fa-map-marker-alt text-primary me-2"></i>{SolutionData.name}</p> {*/}
                                            </div>
                                          
                                        </div>
                                    </div>
                                ) })}
                          
                        </div>
                           
                    </div>   
                    <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.1s" style={{margin: '100px 0'}}>
                       { countData   !=  SolutionData.length   && (
                            // Render the icon if the condition is met
                            <button  className='btn btn-primary' onClick={handleLoadMore} >
                                Browse More Solution 
                                  </button>
                          )}
        
                    </div>  
                </div>
            </div>
        </div>
    </>
  )
}

export default ImgDetails
